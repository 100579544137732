.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: 1500;
    opacity: 0.8;
    pointer-events: none;
    background-color: var(--colors-orange);
}
