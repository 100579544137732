@import 'breakpoints';
@import 'animations';

// -------------------
// OneTrust
// -------------------

@keyframes scale {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

%unsets {
    position: unset;
    display: unset;
    overflow: unset;
    width: unset;
    height: unset;
    padding: unset;
    border: unset;
    border-radius: unset;
    margin: unset;
    appearance: unset;
    background: unset;
    box-shadow: unset;
    color: unset;
    float: unset;
    font: unset;
    letter-spacing: unset;
    line-height: unset;
    opacity: unset;
    outline: unset;
    text-decoration: unset;
    transform: unset;
}

// Double ID selector to override OneTrust's !important
#onetrust-consent-sdk#onetrust-consent-sdk {
    // --------------
    // Banner
    // --------------

    #onetrust-banner-sdk {
        max-width: 500px;
        border: none;
        border-radius: 2rem;
        box-shadow: none;

        &:focus {
            outline: none;
        }
    }

    #onetrust-banner-sdk.otFloatingRoundedCorner {
        bottom: 1em;
        left: 1em;
        animation: scale $default $easeOutQuad 4.5s forwards;
        box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 20%);
        opacity: 0;
        transform: scale(0);
        transform-origin: bottom left;
    }

    // --------------
    // Dialog
    // --------------

    .onetrust-pc-dark-filter {
    }

    #onetrust-pc-sdk#onetrust-pc-sdk {
    }

    #onetrust-policy-text {
        margin-bottom: 1rem;
        font-size: 12px;
    }

    #onetrust-button-group {
        margin: 1rem 0 0;

        button {
            width: 100%;
            border-radius: 0;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .banner-actions-container {
        display: flex;
        gap: 1rem;
    }

    button#onetrust-pc-btn-handler {
        margin-top: 1rem;
    }

    .banner-header {
        display: none;
    }

    .ot-optout-signal {
        display: flex;
        align-items: center;
        margin: 0 0 2rem;
        color: rgb(50, 174, 136);
        font-size: 1.4rem;

        span {
            padding: 1rem 0;
        }
    }

    .ot-optout-icon {
        margin-top: -5px;
    }

    #onetrust-close-btn-container {
        top: 3px;
        right: 3px;
    }

    #onetrust-group-container {
        margin: 0;
    }

    #onetrust-policy-text,
    #onetrust-button-group-parent {
        padding: 0;
    }

    .ot-sdk-container {
        padding: 3rem;
    }
}

#onetrust-pc-sdk#onetrust-pc-sdk {
    box-sizing: border-box;
    border-radius: 2rem;

    * {
        box-sizing: border-box;
    }

    [role='alertdialog'] {
        display: grid;
        grid-template-rows: min-content 1fr min-content;
    }

    .ot-pc-header {
        position: relative;
        width: 100%;
        height: auto;
        padding: 2rem 3rem;
    }

    #ot-pc-content {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 2rem 3rem;
        margin: 0;
    }

    .ot-pc-footer {
        position: relative;
        width: 100%;
        max-height: fit-content;
    }

    .ot-pc-footer-logo {
        display: none;
    }

    button {
        border-radius: 0 !important;
        margin: 0;
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    #close-pc-btn-handler {
        top: 8px;
        right: 8px;
    }

    .ot-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 3rem 3rem;
        gap: 1rem;
    }

    .ot-accordion-layout {
        .ot-acc-hdr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .ot-cat-header {
            margin-top: 0.3em;
        }
    }
}

#ot-sdk-btn-floating.ot-floating-button {
    display: none;
}
